import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LegalQuotesIntro'

// markup
const LatQuotes_l = () => {
  return (
   <Layout >
      <Head title='Latin Legal Maxims, Writs and Expressions - L'/>
      <h1>Legal Latin maxims and expressions</h1>
     <h2>L</h2>
     <LatQuotesIntro />
    
     <p><b>Leges posteriores priores contrarias abrogant</b> - Subsequent laws repeal prior conflicting ones.</p>
<p><b>Legibus sumptis desinentibus legibus naturae utendum est</b> - When laws imposed by the State fail, we must use the laws of nature.</p>
<p><b>Lex aliquando sequitur aequitatem</b> - The law sometimes follows equity.</p>
<p><b>Lex citius tolerare vult privatum damnum quam publicum malum</b> - The law would rather tolerate a private injury than a public evil.</p>
<p><b>Lex dabit remedium</b> - The law will give a remedy.</p>
<p><b>Lex dilationes abhorret</b> - The law abhors delays.</p>
<p><b>Lex est judicum tutissimus ductor</b> - The law is the safest guide for judges.</p>
<p><b>Lex est sanctio sancta jubens honesta et prohibens contraria</b> - The law is a sacred sanction, commanding what is right and prohibiting the contrary.</p>
<p><b>Lex indendit vicinum vicini facta scire</b> - The law presumes that one neighbor knows the acts of another.</p>
<p><b>Lex necessitatis est lex temporis i.e. instantis</b> - The law of necessity is the law of time, that is time present.</p>
<p><b>Lex neminem cogit ad vana seu impossiblia</b> - The law compels no one to do vain or impossible things.</p>
<p><b>Lex nil frustra facit</b> - The law does nothing in vain.</p>
<p><b>Lex non a rege est violanda</b> - The law must not be violated even by the King.</p>
<p><b>Lex non deficere potest in justitia exhibenda</b> - The law cannot fail in dispensing justice.</p>
<p><b>Lex non novit patrem, nec matrem; solam veritatem</b> - The law does not know neither father nor mother, only the truth.</p>
<p><b>Lex non oritur ex injuria</b> -  The law does not arise from a mere injury.</p>
<p><b>Lex non requirit verificari quod apparet curiae</b> - The law does not require that to be proved which is apparent to the Court.</p>
<p><b>Lex non favet delicatorum votis</b> - The law does not favor the wishes of the dainty.</p>
<p><b>Lex plus laudatur quando ratione probatur</b> - The law is the more praised when it is supported by reason.</p>
<p><b>Lex prospicit not respicit</b> - The law looks forwared, not backward.</p>
<p><b>Lex punit mendaciam</b> - The law punishes falsehood.</p>
<p><b>Lex rejicit superflua, pugnatia, incongrua</b> - The law rejects superfluous, contradictory and incongruous things.</p>
<p><b>Lex spectat naturae ordinem</b> - The law regards the order of nature.</p>
<p><b>Lex succurrit ignoranti</b> - The law succors the ignorant.</p>
<p><b>Lex tutissima cassis, sub clypeo legis nemo decipitur</b> - Law is the safest helmet; under the shield of the law no one is deceived.</p>
<p><b>Lex uno ore omnes alloquitur</b> - The law speaks to all through one mouth.</p>
<p><b>Longa possessio est pacis jus</b> - Long possession is the law of peace.</p>
<p><b>Longa possessio parit jus possidendi et tollit actionem vero domino</b> - Long possession produces the right of possession and takes away from the true owner his action.</p>

   </Layout>
  )
}

export default LatQuotes_l
